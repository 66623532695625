<div class="influencers-list-container">
  <div class="header-container">
    <div class="top-section">
      <div class="group-info">
        <ai-tooltip placement="bottom" style="width: max-content;" [tooltips]="[groupInfo?.name]"
          [disabled]="(groupInfo?.name)?.length <= 45">
          <div class="main-title">{{groupInfo?.name|truncate:45}}</div>
        </ai-tooltip>
        <div class="seperator" *ngIf="showGroupTags"></div>
        <div class="group-info-group-tags" *ngIf="showGroupTags">
          <div *ngFor="let tag of (groupInfo.tags.length <= 3 ? groupInfo.tags : groupInfo.tags.slice(0, 2));">
            {{tag}}
          </div>
          <div *ngIf="groupInfo.tags.length > 3">
            <ai-tooltip placement="bottom" [tooltips]="[groupExtendedTags]">
              +{{groupInfo.tags.length - 2}}
            </ai-tooltip>
          </div>
        </div>
        <div class="seperator"></div>
        <ai-button *ngIf="groupInfo" prefix="far fa-pen-to-square" class="group-info-edit" type="icon" size="small"
          (click)="editGroup()">
        </ai-button>
      </div>
      <div class="action-button">
        <ai-button *ngIf="platformCanBeShared()" customClasses="background-white" type="outline" size="large" label="SHARE"
          (click)="openShareGroupModal()" [disabled]="!groupInfo || groupInfluencers?.data?.length === 0"></ai-button>
        <ai-button customClasses="background-white" type="outline" size="large" label="UPLOAD INFLUENCERS"
          (click)="openProfileInfluencersModal()"></ai-button>
        <ai-dropdown [stateless]="true" [size]="'small'" [options]="dropDownOptions" [multi]="false"
          (action)="contextMenuUpdate($event)"
          [buttonOptions]="{ size:'large', type: 'icon', prefix: 'fa-regular fa-ellipsis-vertical' }">
        </ai-dropdown>
      </div>
    </div>
    <div class="bottom-section" *ngIf="groupInfo">
      <div class="created-info">
        <div class="body-2">Created By:</div>
        <div class="sub-heading-h2">{{groupInfo?.ownerName || groupInfo?.ownerEmail}}</div>
      </div>
      <div class="updated-at" *ngIf="groupLastUpdateAt">
        <div class="body-2">Last Updated:</div>
        <div class="sub-heading-h2">{{groupLastUpdateAt}}</div>
      </div>
      <div class="updated-by" *ngIf="groupLastUpdateBy">
        <div class="body-2">Last Updated By:</div>
        <div class="img-group">
          <ai-tooltip placement="top" [tooltips]="[groupLastUpdateBy]">
            <div class="user-avatar body-3">{{getInitials(groupLastUpdateBy)}}</div>
          </ai-tooltip>
        </div>
      </div>
      <div class="shared-info" *ngIf="groupSharedWith?.length > 0">
        <div class="body-2">Shared With:</div>
        <div *ngIf="groupSharedWith?.length > 0" class="img-group">
          <ng-container *ngFor="let user of groupSharedWith; index as i; count as total">
            <ng-container *ngIf="(i < 3 && total > 4) || (i < 4 && total <= 4)">
              <ai-tooltip placement="top" [tooltips]="[user.split(':')[1]]">
                <div class="user-avatar body-3">{{getInitials(user.split(':')[1])}}</div>
              </ai-tooltip>
            </ng-container>
            <ai-tooltip placement="bottom" *ngIf="i === 3 && total > 4"
              [tooltips]="getRemainingSharedWith(groupSharedWith)">
              <div class="user-avatar body-3 additional-count af-caption">
                +{{total-3}}
              </div>
            </ai-tooltip>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="body-divider"></div>
  <div class="content">
    <ng-container *ngTemplateOutlet="!isEmptyState ? nonEmptyState : emptyState">
    </ng-container>
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
    <div *ngIf="isLoading" class="loader">
      <ngx-loader></ngx-loader>
    </div>
  </div>

  <ng-template #emptyState>
    <ngx-empty-widget-group *ngIf="!isLoading" [platform]="platformV2"
      [selectedGroup]="groupInfo"></ngx-empty-widget-group>
  </ng-template>

  <ng-template #nonEmptyState>
    <div>
      <ngx-group-stats *ngIf="activeGroupSlugName"   #groupStats [slugName]="activeGroupSlugName" [groupInfo]="groupInfo"
        [platform]="platform"></ngx-group-stats>
      <ng-container *ngTemplateOutlet="groupActions"></ng-container>
       <div  *ngIf="!isLoading" class="influencer-table">
      <ngx-table #influencerTable (loadNextPage)="loadNextPage()" [hasMoreData]="hasMoreData" [paginationLoader]="paginationLoader" class="modern" infiniteScrollContainer=".influencer-table" [hideBorders]="false"
      [showCheckbox]="true"  [loading]="overlayLoadingFlag" (loadFullTableDataEvent)="loadFullTableDataEvent()" [showTableWithEmptyRows]="true" [stickyHeader]="true" [data]="groupInfluencers?.data"
      (lastClicked)="rowClick($event)" (selectAllEvent)="selectAllEvent($event)">
      <ng-template #headers>
        <th>
          <div class="influencers-header">Influencers <i class="far fa-arrow-up-a-z" [class.disabled]="isLoading" (click)="sortData('influencer')"
            ></i> </div>
        </th>
        <th>
          <div>Followers  <i class="far fa-sort" [class.disabled]="isLoading" (click)="sortData('followers')"
            ></i></div>
        </th>
        <th>
          <div>Eng Rate  <i class="far fa-sort" [class.disabled]="isLoading" (click)="sortData('engagementRatio')"
            ></i></div>
        </th>
        <th>
          <div>EMV</div>
        </th>
        <th>
          <div>Locations</div>
        </th>
        <th *ngIf="isGroupSharedWithCollaborator">
          <div class="reviewedStatusHeader">
            <div class="ai-mr10">Status</div>
            <ai-dropdown [stateless]="true" [options]="reviewStatusDropdownOptions"
              (action)="statusFilterUpdated($event)"
              [buttonOptions]="{ size:'small', type: 'ghost', prefix: 'fa-regular fa-filter', disabled: isLoading }"
              data-cy="platform-filter-dropdown">
            </ai-dropdown>
          </div>
        </th>
        <th>Actions</th>
      </ng-template>
      <ng-template #rows let-row>
        <td [style.textAlign]="'left'" [style.paddingLeft]="'14px'" class="profile-cell">
          <ngx-influencer-profile-card tileType="modern" [profileInfo]="getInfluencerProfileCardInfo(row)">
          </ngx-influencer-profile-card>
        </td>
        <td>{{ (row.followersCount || row.subscribers || row.followerCount || 0) | formatNumber:
          2}}</td>
        <td>{{(row.engagementRate || 0) | formatNumber: 2}}</td>
        <td>
          {{(row.emv || 0) | currency:currency.trim() :'symbol':'1.0'}}
        </td>
        <td>
          <ai-tooltip placement="top" style="width: max-content;"
            [tooltips]="[getInfluencerLocations(row.countries, row.countries.length)]"
            [disabled]="row.countries.length <= 2">
            {{getInfluencerLocations(row.countries)}}
          </ai-tooltip>
        </td>
        <td *ngIf="isGroupSharedWithCollaborator">
          <div class="reviewedStatus"
            [ngClass]="{'pending': row.reviewedStatus === 'PENDING', 'approved': row.reviewedStatus === 'APPROVED' , 'rejected' : row.reviewedStatus === 'REJECTED' }">
            {{row.reviewedStatus === 'PENDING' ? 'Not Reviewed' : row.reviewedStatus | titlecase}}</div>
        </td>
        <td>
          <i [class]="(row.latestNote?'fa-solid fa-notes':'fa-regular fa-notes-medical')+' action-icon note-icon'"
            (click)="openInfluencerNotesSideBar(row)"></i>
          <i class="far fa-trash-can action-icon delete-icon" [class.disabled]="isDeleteDisabled"
            (click)="deleteInfluencer(row)"></i>
        </td>
      </ng-template>
    </ngx-table>
     </div>
    </div>
  </ng-template>
</div>

<ng-template #groupActions>
  <div class="group-actions-container af-sub-heading-h2"  *ngIf="!isLoading">
    <div *ngIf="selectedRowsCount" class="af-body-4 selected-text">Selected {{selectedRowsCount}}/{{groupInfluencers.total}}</div>
    <div class="excluded-influencers-info" *ngIf="groupExcludedInfluenersLength>0">
      <i class="fa-regular fa-user-xmark"></i>
      <span>Excluded Influencers ({{groupExcludedInfluenersLength}})</span>
      <ai-button label="View" type="text" typography="sub-heading-h2"
        (click)="openExcludedInfluencersModal()"></ai-button>
    </div>
    <div class="toolbar">
      <div class="search-group-input">
        <form>
            <ai-input size="small" placeholder="Search influencers by name" [formControl]="search"></ai-input>
        </form>
        <div class="search-icon" >
          <i class="fa-regular fa-search body-2"></i>
        </div>
    </div>
      <ai-button class="sendMail" type="text" label="Send Mail" typography="sub-heading-h2" size="medium"
        [disabled]="isSendMailDisabled" (click)="sendMail()"></ai-button>

      <ai-button [aiDropdownTriggerFor]="topAddTo" (click)="updateSelectedInfluencers()" label="ADD TO" type="fill" split=true suffix="far fa-chevron-down"
        color="primary" typography="body-3" size="small" [disabled]="!selectedRowsCount"></ai-button>
      <ai-template-dropdown #topAddTo width="330" height="280">
        <ng-template>
          <ngx-add-to [firstTab]="tabEnum.Campaign" [influencers]="selectedInfluencers"
            (onClose)="addToClosed()"></ngx-add-to>
        </ng-template>
      </ai-template-dropdown>
      <ai-dropdown [stateless]="true" [size]="'small'" [options]="groupActionDropDownOptions" [multi]="false"
        (action)="contextMenuUpdate($event)"
        [buttonOptions]="{ size:'small', type: 'icon', prefix: 'fa-regular fa-ellipsis-vertical' }">
      </ai-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #sidebar>
  <nb-sidebar right tag="notes" state="collapsed">
    <div class="sidebar-content">
      <div class="sidebar-header">
        <i class="fa fa-close close-sidebar" (click)="closeSidebar()"></i>
        <span *ngIf="showAllNotesSidebar">Notes</span>
        <a class="nb-user-link" href="{{getInfluencerProfileUrl(sidePanelInfluencer)}}" target="_blank"
          *ngIf="sidePanelInfluencer && showInfluencerNoteSidebar">
          <nb-user class="influencer-panel" [name]="sidePanelInfluencer.name"
            [picture]="sidePanelInfluencer.profilePicture" [title]="sidePanelInfluencer.username" size="large">
          </nb-user>
        </a>
      </div>
      <ngx-notes-sidebar *ngIf="showAllNotesSidebar" [platform]="platformV2" [selectedGroupSlug]="groupInfo?.slugName"
        [page]="'groups'" [influencers]="groupInfluencers?.data">
      </ngx-notes-sidebar>
      <ngx-notes-sidebar *ngIf="sidePanelInfluencer && showInfluencerNoteSidebar" [platform]="platformV2"
        [selectedGroupSlug]="groupInfo?.slugName" [page]="'campaigns'"
        [influencerUsername]="encodeUsername(sidePanelInfluencer.username)">
      </ngx-notes-sidebar>
    </div>
  </nb-sidebar>
</ng-template>