<div class='table'>
  <div class="content">
    <div class="header-space print-graphics">&nbsp;</div>
    <router-outlet></router-outlet>
    <div class="footer-space print-graphics">&nbsp;</div>
  </div>
</div>
<!--TODO: confirm footer/header general from with design team-->
<div class='header print-graphics' [ngClass]='{"report":documentType==="report"}'>
  <div class='logo'>
    <ng-container *ngIf="!useWhiteLabelLogo">
      <img src="../../../../assets/images/print/logo.png" alt="">
    </ng-container>
    <ng-container *ngIf="useWhiteLabelLogo">
      <img affLogo>
    </ng-container>
    <div class='link'>
      <h1>affable.ai</h1>
    </div>
  </div>
  <div class='slogan' *ngIf="!useWhiteLabelLogo">
    <h4> Influencer Marketing Platform for data-driven marketers</h4>
    <p>
      End-to-end Influencer Marketing Platform for global brands and agencies
    </p>
  </div>

</div>
<!-- removed as a temporary patch because it is covering actual content -->
<!-- <div class='footer print-graphics'>
  <p>
    This pdf is generated on <b>{{generationDate}}</b><b  *ngIf="!useWhiteLabelLogo">, by Affable.ai</b>
  </p>
</div> -->
