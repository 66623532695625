/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  ChatFilterTypes,
  FilterType,
  KeyValue,
  MailType,
  Platforms,
  PlatformsV2,
  SortDirection,
  SortType,
} from '../../enums';
import {
  ChatPreview,
  EmailTemplate,
  FileData,
  FilterSource,
  LastMessageWithInfluencerDetails,
  MessageHistoryWithInfluencerDetails,
  OpenChatDetails,
  OutboxMessageWithInfluencerInfo,
  RetryChatsResponse,
} from '../../interfaces';
import { UserService } from './user.service';
import { UserguidingService } from './userguiding.service';

const templatesPath = '/api/chats/templates/';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  lastChat: LastMessageWithInfluencerDetails;
  fieldUpdate = new Subject<{ field: string; value: string | boolean; chatId: string }>();
  pinnedChats = 0;
  readonly pinnedChatsLimit = 5;
  noOfMessagesSent = 0;
  constructor(private http: HttpClient, private userService: UserService, private userguiding: UserguidingService) {}

  previewChat(
    username: string,
    campaignSlugName: string,
    subject: string,
    msg: string,
    files: File[],
    platform: PlatformsV2,
  ): Promise<ChatPreview> {
    const formData = this.getFormdata([username], campaignSlugName, subject, msg, files, platform);
    return this.http.post<ChatPreview>(`${environment.api}/api/chats/preview`, formData).toPromise();
  }

  async hotfixChat(
    // code will be deprecated after chat flow job release
    usernames: string[],
    campaignSlugName: string,
    subject: string,
    msg: string,
    files: File[],
    platform: PlatformsV2,
    individualUsersMessages?: Map<string, string>,
    mailType: MailType = MailType.default,
  ): Promise<{ success: number; failure: number }> {
    let success = 0;
    let failure = 0;
    const delay = (ms) => new Promise((_) => setTimeout(_, ms));
    const minDelay = delay(2000);
    for (const username of usernames) {
      const formData = this.getFormdata(
        [username],
        campaignSlugName,
        subject,
        msg,
        files,
        platform,
        individualUsersMessages,
        mailType,
      );
      await this.http
        .post<{ success: number; failure: number }>(`${environment.api}/api/chats/initiateChat`, formData)
        .pipe(
          tap((status) => {
            if (status?.success > 0) {
              this.userguiding.track('Sent invite to an influencer', { campaignSlugName, usernames });
              this.userguiding.identify({ invited_influencer: 1 });
            }
          }),
        )
        .toPromise()
        .then((status) => {
          success += status.success;
          failure += status.failure;
        })
        .catch(() => {
          failure++;
        });
      this.noOfMessagesSent++;
      await minDelay;
    }
    this.noOfMessagesSent = 0;
    return { success, failure };
  }

  async startNewChat(
    usernames: string[],
    campaignSlugName: string,
    subject: string,
    msg: string,
    files: File[],
    platform: PlatformsV2,
    individualUsersMessages?: Map<string, string>,
    mailType: MailType = MailType.default,
  ): Promise<{ success: number; failure: number }> {
    if ((await this.userService.getClientUser(false)).integratedEmails.length && usernames.length >= 2) {
      return this.hotfixChat(
        usernames,
        campaignSlugName,
        subject,
        msg,
        files,
        platform,
        individualUsersMessages,
        mailType,
      );
    }
    const formData = this.getFormdata(
      usernames,
      campaignSlugName,
      subject,
      msg,
      files,
      platform,
      individualUsersMessages,
      mailType,
    );
    return this.http
      .post<{ success: number; failure: number }>(`${environment.api}/api/chats/initiateChat`, formData)
      .pipe(
        tap((status) => {
          if (status?.success > 0) {
            this.userguiding.track('Sent invite to an influencer', { campaignSlugName, usernames });
            this.userguiding.identify({ invited_influencer: 1 });
          }
        }),
      )
      .toPromise()
      .then((status) => status);
  }

  getTemplate(
    category: string,
    params: HttpParams | { [param: string]: string | string[] },
  ): Promise<{ template: string; subject: string }> {
    return this.http
      .get<{ template: string; subject: string }>(`${environment.api}${templatesPath}${category}`, { params })
      .toPromise();
  }

  createTemplate(newDoc: Partial<EmailTemplate>): Promise<EmailTemplate> {
    return this.http.post<EmailTemplate>(`${environment.api}${templatesPath}`, newDoc).toPromise();
  }

  updateTemplate(id: string, update: Partial<EmailTemplate>): Promise<EmailTemplate> {
    return this.http.put<EmailTemplate>(`${environment.api}${templatesPath}${id}`, update).toPromise();
  }

  deleteTemplate(id: string, campaignSlugName: string): Promise<any> {
    return this.http.delete(`${environment.api}${templatesPath}${id}?campaignSlugName=${campaignSlugName}`).toPromise();
  }

  updateDefaultTemplate(campaignSlugName: string, id: string, _default: boolean): Promise<EmailTemplate> {
    return this.http
      .put<EmailTemplate>(`${environment.api}${templatesPath}${id}/default`, {
        campaignSlugName,
        default: _default,
      })
      .toPromise();
  }

  getAllChats(
    campaignSlugName: string,
    platform: PlatformsV2 = Platforms.instagram,
    sortOrder: SortDirection = SortType.desc,
    chatFilter: ChatFilterTypes = FilterType.none,
  ): Promise<OpenChatDetails> {
    return this.http
      .get<OpenChatDetails>(`${environment.api}/api/chats/openChatsForCurrentClient`, {
        params: { campaignSlugName, platform: platform.toString().toUpperCase(), sortOrder, chatFilter },
      })
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((v) => (this.pinnedChats = (v.openChatDetails || []).filter((c) => c.pinned).length)),
      )
      .toPromise();
  }

  searchInfluencers(
    campaignSlugName: string,
    platform: PlatformsV2 = Platforms.instagram,
    searchText: string,
    proposalStatus: string,
    withAttachments: boolean,
    messageStatus: string[],
    searchType: FilterSource,
  ): Promise<OpenChatDetails> {
    const params = {
      campaignSlugName,
      platform: platform.toString().toUpperCase(),
      searchText: searchText,
      proposalStatus: proposalStatus ? proposalStatus : undefined,
      withAttachments: withAttachments ? withAttachments?.toString() : undefined,
      messageStatus: messageStatus ? messageStatus : undefined,
      searchType,
    };

    return this.http
      .get<OpenChatDetails>(`${environment.api}/api/chats/search`, {
        params: _.omitBy(params, _.isNil),
      })
      .toPromise();
  }

  getMessageHistoryWithInfluencerDetails(
    influencerUsername: string,
    campaignSlugName: string,
    platform: PlatformsV2,
    fetchBeforeDate?: Date,
  ): Promise<MessageHistoryWithInfluencerDetails> {
    if (!influencerUsername) {
      return Promise.resolve(undefined);
    }
    const params = {
      campaignSlugName,
      platform: platform.toString().toUpperCase(),
    };
    if (fetchBeforeDate) {
      params['fetchBeforeDate'] = fetchBeforeDate;
    }
    return this.http
      .get<MessageHistoryWithInfluencerDetails>(`${environment.api}/api/chats/chatHistoryWith/${influencerUsername}`, {
        params,
      })
      .toPromise();
  }

  searchMessages(
    campaignSlugName: string,
    platform: PlatformsV2,
    searchText: string,
    sortOrder: SortDirection = SortType.desc,
    chatFilter: ChatFilterTypes = FilterType.none,
    // messageStatus: string[],
    // searchType: FilterSource,
  ): Promise<OpenChatDetails> {
    const params = {
      campaignSlugName,
      platform: platform.toString().toUpperCase(),
      searchText: searchText,
      sortOrder,
      chatFilter,
      // messageStatus: messageStatus ? messageStatus : undefined,
      // searchType,
    };

    return this.http
      .get<OpenChatDetails>(`${environment.api}/api/chats/search`, {
        params: _.omitBy(params, _.isNil),
      })
      .toPromise();
  }

  saveMessageReply(
    campaignSlugName: string,
    influencerUsername: string,
    replyMessage: string,
    files: File[],
    platform: PlatformsV2,
  ): Promise<FileData[]> {
    const formData = new FormData();
    formData.append('influencerUsername', influencerUsername);
    formData.append('campaignSlugName', campaignSlugName);
    formData.append('message', replyMessage);
    formData.append('platform', platform.toString().toUpperCase());
    for (let i = 0; i < files?.length; i++) {
      formData.append('file' + i, files[i], files[i].name);
    }
    return this.http.post<FileData[]>(`${environment.api}/api/chats/saveMessageAndSendMail/`, formData).toPromise();
  }

  updateMessageStatus(chatId: string, event: string, message_timestamp: Date): Promise<any> {
    return this.http
      .put(`${environment.api}/api/chats/message-status`, {
        chat_id: chatId,
        event,
        message_timestamp,
      })
      .toPromise();
  }

  updateChat(
    campaignSlugName: string,
    influencerId: string,
    updateDoc: KeyValue,
    platform = Platforms.instagram,
  ): Promise<any> {
    return this.http
      .put(
        `${
          environment.api
        }/api/campaigns/v2/${campaignSlugName}/${influencerId}/chat?platform=${platform.toUpperCase()}`,
        updateDoc,
      )
      .toPromise();
  }

  getSupportedTemplatingVaribles(scope = 'chats'): Promise<string[]> {
    return this.http.get<string[]>(`${environment.api}/api/chats/templating/variables?scope=${scope}`).toPromise();
  }

  getTemplatesForRegistration(scope = 'registration'): Promise<{
    types: [];
    supportedVariables: string[];
    invitationTypes: { type: string; templates: EmailTemplate[] }[];
  }> {
    return this.http
      .get<{
        types: [];
        supportedVariables: string[];
        invitationTypes: { type: string; templates: EmailTemplate[] }[];
      }>(`${environment.api}/api/instagram/profile/email-templates?scope=${scope}`)
      .toPromise();
  }

  downloadFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  getFormdata(
    usernames: string[],
    campaignSlugName: string,
    subject: string,
    msg: string,
    files: File[],
    platform: PlatformsV2,
    individualUsersMessages?: Map<string, string>,
    mailType: MailType = MailType.default,
  ): FormData {
    const formData = new FormData();
    formData.append('usernames', usernames.join(','));
    formData.append('campaignSlugName', campaignSlugName);
    formData.append('subject', subject);
    formData.append('message', msg);
    formData.append('platform', platform.toString().toUpperCase());
    formData.append('mailType', mailType);
    if (individualUsersMessages) {
      formData.append('individualUsersMessages', JSON.stringify(Array.from(individualUsersMessages.entries())));
    }
    for (let i = 0; i < files?.length; i++) {
      formData.append('file' + i, files[i], files[i].name);
    }
    return formData;
  }

  getPendingOutboxMessages(campaignSlugName: string): Promise<OutboxMessageWithInfluencerInfo[]> {
    return this.http
      .get(`${environment.api}/api/chats/${campaignSlugName}/outboxMessages`)
      .toPromise()
      .then((response) => {
        return (response['outboxMessages'] as OutboxMessageWithInfluencerInfo[]) || [];
      });
  }

  retryPendingMessage(campaignSlugName: string, outboxMessageId: string): Promise<RetryChatsResponse> {
    return this.http
      .post<RetryChatsResponse>(
        `${environment.api}/api/chats/${campaignSlugName}/outboxMessages/retry/${outboxMessageId}`,
        {},
      )
      .toPromise();
  }
}
